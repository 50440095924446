import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère le compte connecté
 */
const getMe = async () => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/customers/me`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const editMe = async (customer) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/customers/me`, {
      firstname: customer.firstname,
      lastname: customer.lastname,
      phone: customer.phone,
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const editMySource = async () => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/customers/me/source`, {
      brandKnowledge: 'CONSTRUCTION_SITE_DISPLAY',
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const editPassword = async (oldPassword, newPassword) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/customers/me/password`, { oldPassword, newPassword });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Sauvegarder un projet
 * @param {object} project: projet / configuration à sauvegarder
 */
const saveProject = async (
  project,
) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/customers/me/project-drafts`, project);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupérér un projet
 * @param {object} projectDraftId: id de l'avant projet
 */
const getProject = async (
  projectDraftId,
) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/customers/me/project-drafts/${projectDraftId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupérer un pdf un projet
 * @param {object} projectDraftId: id de l'avant projet
 */
const getProjectPdf = async (
  projectDraftId,
  token,
) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/customers/me/project-drafts/${projectDraftId}/pdf?token=${token}`, { responseType: 'arraybuffer' });
    return response.data;
  } catch (er) {
    throw er;
  }
};

/**
 * Récupérer le nom d'un client formatté un client
 * @param {object} customer: objet du customer à retourner
 */
const getCustomersNames = (customer) => {
  let customersName;
  if (customer) {
    if (customer.firstname && customer.lastname) {
      customersName = `${customer.firstname.charAt(0).toUpperCase() + customer.firstname.slice(1)} ${customer.lastname.charAt(0).toUpperCase() + customer.lastname.slice(1)}`;
    } else if (customer.firstname) {
      customersName = `${customer.firstname.charAt(0).toUpperCase() + customer.firstname.slice(1)}`;
    } else if (customer.lastname) {
      customersName = `${customer.lastname.charAt(0).toUpperCase() + customer.lastname.slice(1)}`;
    }
  }
  return customersName;
};

const createLeadMagnet = async (customer, configuration) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/customers/lead-magnets`, {
      ...customer,
      projectDraft: configuration,
    });
    return response.data.data;
  } catch (e) {
    throw e;
  }
};

const api = {};
api.getMe = getMe;
api.editMe = editMe;
api.editMySource = editMySource;
api.editPassword = editPassword;
api.saveProject = saveProject;
api.getProject = getProject;
api.getProjectPdf = getProjectPdf;
api.getCustomersNames = getCustomersNames;
api.createLeadMagnet = createLeadMagnet;

export default api;
